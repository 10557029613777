import { gql, TypedDocumentNode } from '@apollo/client'
import productCardFragment from '@emico-hooks/product-card-fragment'

import { ProductFragment } from './ProductFragment.generated'
import { productConfigurableOptionsFragment } from './ProductConfigurableOptionsFragment'
import { productParentFragment } from './ProductParentFragment'
import { productLabelListFragment } from '@emico/product-label-fragment'

export const productFragment = gql`
  fragment ProductFragment on ProductInterface {
    sku
    uid
    name
    stockStatus
    isSalableInStore: salableInStore
    tweakwiseItemNumber
    ratingSummary
    reviewCount
    urlKey
    isMainProduct

    canonicalUrl
    metaTitle
    metaDescription
    shortDescription {
      html
    }
    description {
      html
    }

    preorder {
      atpDate
      atpDateFormatted
      isPreorder
    }

    image {
      url
    }

    smallImage {
      url
      label
      disabled
    }

    relatedProducts {
      ...ProductCardFragment
    }

    mediaGallery {
      label
      position
      url
      disabled

      ... on ProductVideo {
        videoContent {
          mediaType
          videoMetadata
          videoProvider
          videoTitle
          videoUrl
        }
      }
    }

    # Custom Berg
    ambianceGallery {
      label
      position
      url
      disabled
    }

    tweakwiseTemplates {
      productCrosssellTemplate
    }

    # Custom Berg
    highlightedAttributes {
      attributeCode
      attributeLabel
      attributeValue
      attributePosition
    }

    dynamicAttributes {
      attributeLabel
      attributeValue
    }

    # Custom Berg
    productDownloads {
      title

      downloads {
        url
        filename
        buttonText
      }
    }

    # Custom Berg
    productLabels {
      ...ProductLabelListFragment
    }

    priceRange {
      minimumPrice {
        discount {
          amountOff
          percentOff
        }
        regularPrice {
          currency
          value
        }
        finalPrice {
          currency
          value
        }
      }
    }

    categories {
      ... on CategoryTree {
        uid
        name
        urlKey
        breadcrumbs {
          categoryUid
          categoryName
          categoryUrlKey
          categoryUrlPath
        }
      }
    }

    # Custom Berg
    sparepartLinks {
      product {
        ...ProductCardFragment
      }
      year
    }

    # Custom Berg
    colorVariants {
      uid
      sku
      name
      modelName
      url
      image
      isMainProduct
      status
      isSalable
      isCurrent
    }

    ... on ConfigurableProduct {
      configurableOptions {
        ...ProductConfigurableOptionsFragment
      }
    }

    # Custom Berg
    ... on SimpleProduct {
      parentData {
        ...ProductParentFragment
      }
    }
  }

  ${productCardFragment}
  ${productConfigurableOptionsFragment}
  ${productParentFragment}
  ${productLabelListFragment}
` as TypedDocumentNode<ProductFragment, never>
